import { TimerState } from "./TimerState";

export class Timer {
  targetSeconds: number;
  msRemaining: number;
  startTimestamp: DOMHighResTimeStamp;
  timerState: TimerState;
  elapsedTimeAtPause: number;

  constructor() {
    this.targetSeconds = 0;
    this.msRemaining = 0;
    this.startTimestamp = 0;
    this.timerState = TimerState.READY;
    this.elapsedTimeAtPause = 0;
  }

  setTarget(days: number, hours: number, minutes: number, seconds: number) {
    this.targetSeconds =
      days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;
    if (this.targetSeconds < 0) {
      this.targetSeconds = 0;
    }
    this.reset();
  }

  addDays(days: number) {
    this.addSeconds(days * 24 * 60 * 60);
  }

  addHours(hours: number) {
    this.addSeconds(hours * 60 * 60);
  }

  addMinutes(minutes: number) {
    this.addSeconds(minutes * 60);
  }

  addSeconds(seconds: number) {
    this.targetSeconds += seconds;
    if (this.targetSeconds < 0) {
      this.targetSeconds = 0;
    }

    this.msRemaining += seconds * 1000;
    if (this.msRemaining < 0) {
      this.msRemaining = 0;
    }
  }

  start() {
    if (this.timerState === TimerState.FINISHED) {
      this.reset();
    }
    this.startTimestamp = performance.now();
    this.timerState = TimerState.RUNNING;
    this.step(this.startTimestamp);
  }

  pause() {
    if (this.timerState === TimerState.RUNNING) {
      this.timerState = TimerState.PAUSED;
      this.elapsedTimeAtPause = this.targetSeconds * 1000 - this.msRemaining;
    }
  }

  reset() {
    this.msRemaining = this.targetSeconds * 1000;
    this.elapsedTimeAtPause = 0;
    this.timerState = TimerState.READY;
  }

  timeLeft() {
    return this.msRemaining;
  }

  private step(timestamp: DOMHighResTimeStamp) {
    if (this.timerState === TimerState.RUNNING) {
      this.msRemaining =
        this.targetSeconds * 1000 -
        this.elapsedTimeAtPause -
        (timestamp - this.startTimestamp);

      if (this.msRemaining < 0) {
        this.msRemaining = 0;
      }

      if (this.msRemaining === 0) {
        this.timerState = TimerState.FINISHED;
      }

      if (this.timerState === TimerState.RUNNING) {
        window.requestAnimationFrame((timestamp) => {
          this.step(timestamp);
        });
      }
    }
  }
}
