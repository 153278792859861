
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { Timer } from "@/classes/Timer";
import { Socket, io } from "socket.io-client";
import { PairingSecrets } from "@/classes/PairingSecrets";
import { TimerState } from "@/classes/TimerState";
import QrcodeVue from "qrcode.vue";
import { TimerOptions } from "@/classes/TimerOptions";

@Options({
  components: {
    QrcodeVue,
  },
})
export default class HelloWorld extends Vue {
  TimerState = TimerState; // So we can use TimerState enum values in template
  timer = new Timer();
  socket = io("https://remotetimer.nfshost.com/", { autoConnect: false });
  pairingModalOpen = false;
  pairingSecrets: PairingSecrets | null = null;
  paired = false;
  displayMessage = "";
  options = new TimerOptions();
  quickSetOptionsSeconds = [30, 60, 180, 300, 600, 900, 1800, 3600];
  audibleStatus = "";

  created() {
    this.timer.setTarget(0, 0, 5, 0);
  }

  mounted() {
    this.setupSocket();
  }

  get timerTargetMs() {
    return this.timer.targetSeconds * 1000;
  }

  get timerTargetSecs() {
    return this.timer.targetSeconds;
  }

  get timerMsProgress() {
    return this.timer.targetSeconds * 1000 - this.timer.msRemaining;
  }

  get timeLeftSecs() {
    return Math.round(this.timer.timeLeft() / 1000);
  }

  get timerElapsedPercent() {
    return (this.timerMsProgress / this.timerTargetMs) * 100;
  }

  get timerRemainingPercent() {
    return 100 - (this.timerMsProgress / this.timerTargetMs) * 100;
  }

  get displayTime() {
    const hours = Math.floor(this.timeLeftSecs / (60 * 60));
    const minutes = Math.floor((this.timeLeftSecs / 60) % 60);
    const seconds = Math.floor(this.timeLeftSecs % 60);

    if (this.timerTargetMs >= 60 * 60 * 1000) {
      return `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
        seconds < 10 ? "0" : ""
      }${seconds}`;
    } else {
      return `${
        this.timerTargetSecs >= 600 && minutes < 10 ? "0" : ""
      }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    }
  }

  get timerState() {
    return this.timer.timerState;
  }

  get pairingUrl() {
    return `${location.protocol}//${location.host}${
      this.$router.resolve({ name: "pair" }).href
    }?code=${this.pairingSecrets?.pairingCode}`;
  }

  get pairingDisplayUrl() {
    return `${location.host}${this.$router.resolve({ name: "pair" }).href}`;
  }

  get timerDisplayClasses() {
    if (
      this.options.showWarningColours &&
      [TimerState.RUNNING, TimerState.FINISHED, TimerState.PAUSED].includes(
        this.timerState
      )
    ) {
      if (this.timerRemainingPercent === 0) {
        return "rt-timer--finished";
      } else if (this.timeLeftSecs <= this.timerTargetSecs * 0.1) {
        return "rt-timer--warning";
      }
    }

    return "";
  }

  quickSet(seconds: number) {
    this.timer.setTarget(0, 0, 0, seconds);
    this.audibleStatus = `Timer set to ${this.quickSetLabelAria(seconds)}`;
  }

  quickSetLabel(seconds: number) {
    if (seconds < 60) {
      return `${seconds}s`;
    }

    if (seconds < 3600) {
      return `${seconds / 60}m`;
    }

    return `${seconds / 60 / 60}h`;
  }

  quickSetLabelAria(seconds: number) {
    if (seconds < 60) {
      return `${seconds} second${seconds > 1 ? "s" : ""}`;
    }

    if (seconds < 3600) {
      return `${seconds / 60} minute${seconds / 60 > 1 ? "s" : ""}`;
    }

    return `${seconds / 60 / 60} hour${seconds / 60 / 60 > 1 ? "s" : ""}`;
  }

  startTimer() {
    this.timer.start();
    this.$nextTick(() => {
      (this.$refs["pauseButton"] as HTMLButtonElement).focus();
    });
    this.audibleStatus = "Timer started";
  }

  pauseTimer() {
    this.timer.pause();
    this.$nextTick(() => {
      (this.$refs["startButton"] as HTMLButtonElement).focus();
    });
    this.audibleStatus = "Timer paused";
  }

  resetTimer() {
    this.timer.reset();
    this.$nextTick(() => {
      (this.$refs["startButton"] as HTMLButtonElement).focus();
    });
    this.audibleStatus = "Timer reset";
  }

  showPairingModal() {
    this.pairingModalOpen = true;
    this.socket.connect();
  }

  cancelPairingModal() {
    this.pairingModalOpen = false;
    this.socket.disconnect();
  }

  unpair() {
    this.socket.disconnect();
  }

  setupSocket() {
    this.socket.on("connect", () => {
      console.log(this.socket.id);

      this.socket.emit("initPairing", {});
    });

    this.socket.on("pairingSecretsGenerated", (pairingSecrets: any) => {
      console.log("Received pairing secrets");

      this.pairingSecrets = new PairingSecrets(
        pairingSecrets.pairingId,
        pairingSecrets.pairingCode
      );
    });

    this.socket.on("pairingSuccess", () => {
      console.log("Pairing succeeded");
      this.paired = true;
      this.pairingModalOpen = false;
      this.onTimerStateChanged();
    });

    this.socket.on("startTimer", () => {
      console.log("Start triggered by controller");
      this.startTimer();
    });

    this.socket.on("pauseTimer", () => {
      console.log("Pause triggered by controller");
      this.pauseTimer();
    });

    this.socket.on("resetTimer", () => {
      console.log("reset triggered by controller");
      this.resetTimer();
    });

    this.socket.on("displayMessageChanged", (payload) => {
      console.log(payload);
      console.log(`Display message changed to '${payload.displayMessage}'`);
      this.displayMessage = payload.displayMessage;
    });

    this.socket.on("disconnect", () => {
      this.paired = false;
      this.pairingSecrets = null;
    });
  }

  @Watch("timerState")
  onTimerStateChanged() {
    if (this.paired) {
      console.log("Timer state changed, emitting change");
      this.socket.emit("timerStateChanged", { timerState: this.timerState });
    }

    if (this.timerState === TimerState.FINISHED) {
      this.audibleStatus = "Time's up";

      this.$nextTick(() => {
        (this.$refs["startButton"] as HTMLButtonElement).focus();
      });
    }
  }

  @Watch("timeLeftSecs")
  onTimeLeftSecsChanged() {
    if (this.timerState !== TimerState.RUNNING) {
      return;
    }

    if (this.timeLeftSecs % 60 === 0 && this.timeLeftSecs > 0) {
      const mins = this.timeLeftSecs / 60;
      this.audibleStatus = `${mins} ${mins === 1 ? "minute" : "minutes"} left`;
    }

    if (this.timeLeftSecs === 10) {
      this.audibleStatus = "10 seconds left";
    }
  }
}
